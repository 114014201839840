import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthenticationService {

    constructor(private cookieService: CookieService) { }

    setAuthToken(token: string): void {
        // Set the cookie with desired options
        this.cookieService.set('userToken', token, {
            secure: true, // Ensures it's sent only over HTTPS
            sameSite: 'Strict', // Prevents CSRF
            path: '/', // Makes the cookie accessible to the entire app
        });
    }

    getAuthToken(): string {
        return this.cookieService.get('userToken');
    }

    // Delete the userToken cookie
    deleteAuthToken(): void {
        this.cookieService.delete('userToken', '/'); // Ensure the path matches when deleting
    }
}