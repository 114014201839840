import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormGroup, ReactiveFormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RpcService } from '../rpc.service';
import { LoginFieldBase } from '../login/_form/loginfield-base';
import { LoginFormControlService } from '../login/_form/loginform-control.service';
import { TextboxLogin } from '../login/_form/login-textbox';
import {
  RequestAuthenticateUser, ReplyAuthenticateUser, FieldNameValue,
  RequestGetMasterAdminAuthPrompts, ReplyGetMasterAdminAuthPrompts
} from '../login/authMessages';

import { I18n } from '@ngx-translate/i18n-polyfill';

import { AzureService } from '../azure.service';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-rrot',
  templateUrl: './masteradminlogin.component.html',
  styleUrls: ['../login/login.component.css'],
  providers: [LoginFormControlService, RpcService]
})


export class MasterAdminLoginComponent implements OnInit, AfterViewInit {

  @Input() loginField: LoginFieldBase<any>;
  @Input() form: FormGroup;
  @ViewChildren('dynamicInput') dynamicInputs: QueryList<ElementRef>;
  get isValid() { return this.form.controls[this.loginField.name].valid; }

  showLoading: boolean;
  // form: FormGroup;
  loginFields: LoginFieldBase<any>[];
  wrongCredentials: boolean;
  showErr: boolean;

  autofocusFieldName: string = ""

  requestAuthenticateUser: RequestAuthenticateUser;
  replyAuthenticateUser: boolean;
  replyAuthenticateUserRes: ReplyAuthenticateUser;

  requestAuthPrompts: RequestGetMasterAdminAuthPrompts;
  replyAuthPrompts: ReplyGetMasterAdminAuthPrompts;

  errorMsg: string;
  showLoginForm: boolean;
  showNextStageForm: boolean;
  stageNumber: number;
  masterUserId: number;

  constructor(private i18n: I18n, private lfcs: LoginFormControlService,
    private rpcService: RpcService, private router: Router,
    private renderer2: Renderer2, private azureService: AzureService,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService) {
    this.requestAuthenticateUser = new RequestAuthenticateUser();
    this.requestAuthPrompts = new RequestGetMasterAdminAuthPrompts();
    this.form = this.createEmptyFormGroup();
  }

  ngOnInit() {
    console.log('masteradminlogin')
    this.loginFields = [];
    this.showLoginForm = true;
    this.showLoading = false;
    this.requestAuthPrompts.data.auth_type_id = 1;
    this.rpcService.postRequest(this.requestAuthPrompts)
      .subscribe(
        resAuthPrompts => {
          this.replyAuthPrompts = resAuthPrompts
          this.replyAuthPrompts.data.prompts.forEach(prompt => {
            if (prompt.auth_prompt_type == "PASSWORD") {
              prompt.auth_prompt_type = 'password';
            }
            prompt.auth_prompt_label = this.i18n({ id: prompt.auth_prompt_label, value: "none" });
            this.loginFields.push(
              new TextboxLogin({
                value: prompt.auth_prompt_label,
                id: prompt.auth_prompt_id,
                name: prompt.auth_prompt_name,
                label: prompt.auth_prompt_label,
                description: prompt.auth_prompt_description,
                type: prompt.auth_prompt_type,
                order: prompt.auth_prompt_order
              }));
          });
          this.form = this.lfcs.toFormGroup(this.loginFields.sort((a, b) => a.order - b.order));
        },
        responseGetDefaultAuthInst => {
          this.errorMsg = responseGetDefaultAuthInst
          this.showError(this.errorMsg);
        });
  }

  ngAfterViewInit() {
    this.dynamicInputs.changes.pipe(take(1)).subscribe(() => {
      this.setAutofocus();
    });
    this.cdr.detectChanges(); // Trigger change detection to ensure changes are detected
  }

  onLogin(loginForm: NgForm) {
    this.showLoading = true;
    let list = new Array<FieldNameValue>();
    for (let key in loginForm.value) {
      let fieldVal = new FieldNameValue();
      fieldVal.name = key;
      fieldVal.value = encodeURIComponent(loginForm.value[key]);
      list.push(fieldVal)
    }

    this.requestAuthenticateUser.data.user_fields = list;
    this.requestAuthenticateUser.data.os = this.getOSName();
    this.requestAuthenticateUser.data.browser = this.getBrowserName();
    this.rpcService.postRequest(this.requestAuthenticateUser)
      .subscribe(
        resAuthCreateInstData => {
          this.replyAuthenticateUserRes = resAuthCreateInstData
          this.replyAuthenticateUser = this.replyAuthenticateUserRes.data.authenticated;
          if (this.replyAuthenticateUser) {
            localStorage.setItem('userToken', this.replyAuthenticateUserRes.data.userToken);
            this.authenticationService.setAuthToken(this.replyAuthenticateUserRes.data.userToken);
            this.showLoading = false;
            this.router.navigate(['/workspace']);
          }
          else {
            this.showLoading = false;
            this.wrongCredentials = true;
            //wait 5 Seconds and hide
            setTimeout(function () {
              this.wrongCredentials = false;
            }.bind(this), 5000);
          }
        },
        responseError => {
          this.showLoading = false;
          this.errorMsg = responseError
          this.showError(this.errorMsg);
        });
  }

  mouseenter(event) {
    this.renderer2.addClass(event.target, 'mat-elevation-z9')
  }

  mouseleave(event) {
    this.renderer2.removeClass(event.target, 'mat-elevation-z9')
  }


  createEmptyFormGroup() {
    var tmpArr: LoginFieldBase<any>[] = [];
    tmpArr.push(new TextboxLogin({
      value: '',
      id: 1,
      name: 'dummy',
      label: '',
      description: '',
      type: 'textbox',
      order: 1
    }));
    return this.lfcs.toFormGroup(tmpArr);
  }
  showError(msg: string) {
    msg = msg.toString();

    if (msg.length > 82) {

      msg = msg.substring(0, 82) + "...";

    }
    this.errorMsg = msg;
    this.showErr = true;
    //wait 5 Seconds and hide
    setTimeout(function () {
      this.errorMsg = msg;
      this.showErr = false;
    }.bind(this), 5000);
  }

  getBrowserName(): string {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after "OPR/" 
    if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    return browserName;
  }

  getOSName(): string {

    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;

    var os = 'unknown';
    var clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Linux', r: /(Linux|X11)/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];
    for (var id in clientStrings) {
      var cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    var osVersion = 'unknown';

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        let versions: Array<string>;
        versions = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = versions[1] + '.' + versions[2];
        break;
    }
    return os + ' ' + osVersion;
  }

  private setAutofocus(): void {
    this.dynamicInputs.forEach(input => {
      const element = input.nativeElement as HTMLInputElement;
      if (element.getAttribute('name') === this.autofocusFieldName) {
        element.focus();
      }
    })
  }
}